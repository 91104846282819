<template>
  <div>
    <div class="flex h-screen">
      <div class="m-auto">
        <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
